<template>
  <b-row class="hp-authentication-page">
    <left-item :image-url="imageSrc" />

    <b-col
      cols="12"
      lg="6"
      class="py-sm-64 py-lg-0"
    >
      <b-row
        align-v="center"
        align-h="center"
        class="h-100 mx-4 mx-sm-n32"
      >
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <h1 class="mb-0 mb-sm-24">
            Lupa Kata Sandi
          </h1>
          <p class="mt-sm-8 mt-sm-0 text-black-60">
            Silakan masukkan email, nomor telepon, atau nama pengguna yang terkait dengan akun Anda untuk mengubah kata sandinya.
          </p>

          <b-form class="mt-16 mt-sm-32 mb-8">
            <b-form-group
              label="E-mail :"
              label-for="recoverEmail"
              class="mb-16"
            >
              <b-form-input
                id="recoverEmail"
                v-model="field.email"
                type="email"
                placeholder="Email, No. Telepon, Nama Pengguna"
              />
            </b-form-group>

            <b-button
              variant="danger"
              class="w-100"
              @click="forgotPassword"
              :disabled="isLoading"
            >
              <b-spinner small label="Small Spinner" v-if="isLoading"></b-spinner>
              <span v-else>Kirim Pemulihan Kata Sandi</span>
            </b-button>
          </b-form>

          <div class="hp-form-info text-center">
            <b-link
              class="hp-cursor-pointer text-danger-1 hp-text-color-dark-primary-2 hp-caption"
              to="/pages/authentication/reset-password"
            >
              Kembali ke Halaman Login
            </b-link>
          </div>

          <footer-item />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormInvalidFeedback,
  BToast,
  BSpinner,
} from 'bootstrap-vue'

import LeftItem from '../LeftItem.vue'
import FooterItem from '../FooterItem.vue'

import { forgot } from '../../../../api/auth/authAPI'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormInvalidFeedback,
    BToast,
    BSpinner,
    LeftItem,
    FooterItem,
  },
  data() {
    return {
      imageSrc: require('@/assets/img/pages/authentication/recover-bg.svg'),
      field: {
        email: '',
      },
      fieldError: {
        email: undefined,
      },
      isLoading: false,
    }
  },
  computed: {
    validation() {
      return {
        email: this.fieldError.email ? false : null,
      }
    },
  },
  methods: {
    forgotPassword() {
      forgot({ email: this.field.email }).then(response => {
        if (response.data.status === true) {
          this.isLoading = true;
          setTimeout(() => {
            // Show a success toast
            this.$bvToast.toast(response.data.message, {
              title: 'Success',
              variant: 'success',
              solid: true,
            })

            this.$router.push('/pages/authentication/reset-password')
          }, 500)
          this.isLoading = false;
        }
      }).catch(err => {
        console.error(err)
      })
    },
  },
}
</script>
